<template>
  <form @submit.prevent id="form-wrapper">
    <div class="grid-container">
      <!-- username -->
      <div class="item-wrapper full" :class="{ 'input-error': usernameError }">
        <label for="username" class="form-text-indicator">{{
          $t("register_form.username_label") | uppercase
        }}</label>
        <input
          required
          v-model.trim="userData.Email"
          @keyup="checkUsername"
          type="text"
          class="fixed"
          disabled
          autocomplete="off"
          id="username"
        />
        <!-- <div
          class="helper-icon"
          @mouseover="mouseOver('username')"
          @mouseout="mouseOut('username')"
        >
          <img id="helpIcon" src="../assets/icons/help.png" />
        </div> -->
        <div v-show="modals.username" class="modal">
          <p align="center" class="bold">
            {{ $t("register_form.username_modal.header") | capitalize }}
          </p>
          <p align="center">
            {{ $t("register_form.username_modal.body") | capitalize }}
          </p>
        </div>
        <transition name="fade">
          <div class="error-text" v-if="usernameError">
            <span class="exclamation">!</span>
            <span>{{ $t("register_form.username_error") | capitalize }}</span>
          </div>
        </transition>
      </div>
      <!-- tag number -->
      <!-- 
      <div class="item-wrapper" style="display: none;">
        <label for="tagNumber" class="form-text-indicator">{{
          $t("register_form.tag_number") | uppercase
        }}</label>
        <input
          required
          v-model.trim="userData.UserNumber"
          autocomplete="off"
          class="fixed"
          disabled
          id="tagNumber"
        />
      </div>
      -->
      <!-- password -->
      <div class="item-wrapper full" :class="{ 'input-error': !valid_password && !initialErrorStyle}">
        <label for="pwd" class="form-text-indicator">{{
          $t("register_form.password_label") | uppercase
        }}</label>

        <div class="boxinput">
          <input
            class="width-100"
            required
            v-model.trim="signUpForm.pwd"
            :type="showPassword === true ? 'text' : 'password'"
            autocomplete="off"
            readonly
            onfocus="this.removeAttribute('readonly');"
            @focus="enableErrorStyle()"
            @keyup="clearError('passwordError')"
            id="pwd"
            @input="checkPassword"
          />
          <button class="button" type="button" @click="toggleShow">
            <span class="icon is-small is-right">
              <i class="eyepassword" :class="{ 'eye-slash': showPassword, 'eye': !showPassword }"></i>
            </span>
          </button>            
        </div>          

        <transition name="fade">
          <div class="error-text errorboxinfo" v-if="!valid_password && !initialErrorStyle">
            <!--<span class="exclamation">!</span>-->
            <span>{{ contains_minimum_characters ? "" : $t("register_form.minimum_characters") }}</span>
            <span>{{ contains_uppercase ? "" : $t("register_form.at_least_capital_letter") }}</span>
            <!--<span>{{ contains_number ? "" : $t("register_form.minimum_number") }}</span>-->
            <!--<span>{{ contains_special_character ? "" : $t("register_form.at_least_special_character") }}</span>-->
            <span>{{ contains_spaces ? "" : $t("register_form.should_not_have_spaces") }}</span>
          </div>
        </transition>
      </div>

      <!-- password 2 -->
      <div class="item-wrapper full" :class="{ 'input-error': passwordError }">
        <label for="pwd2" class="form-text-indicator">{{
          $t("register_form.repeat_password_label") | uppercase
        }}</label>
        <div class="boxinput">
          <input
            class="width-100"
            required
            v-model.trim="signUpForm.pwd2"
            :type="showPassword2 === true ? 'text' : 'password'"
            autocomplete="off"
            readonly
            onfocus="this.removeAttribute('readonly');"
            @keyup="clearError('passwordError')"
            id="pwd2"
          />
          <button class="button" type="button" @click="toggleShow2">
            <span class="icon is-small is-right">
              <i class="eyepassword" :class="{ 'eye-slash': showPassword2, 'eye': !showPassword2 }"></i>
            </span>
          </button>            
        </div>
        
        <transition name="fade">
          <div class="error-text" v-if="passwordError">
            <span class="exclamation">!</span>
            <span>{{
              $t("register_form.different_password_error") | capitalize
            }}</span>
          </div>
        </transition>
      </div>
    </div>
    
    <div class="legal-container">
      <!-- Checkboxes intro -->
      <div class="checkbox-intro text-justify">{{ $t("register_form.checkbox_intro") }}</div>
      <!-- Comercial communications checkbox -->
      <div class="checkbox-wrapper item-wrapper full">
        <input
          type="checkbox"
          id="comercialCheckbox"
          v-model="signUpForm.comercialCheckbox"
        />
        <label for="comercialCheckbox" class="text-justify">{{
          $t("register_form.comercial_checkbox")
        }}</label>
      </div>
      <!-- Third parties checkbox -->
      <div class="item-wrapper full">
        <div class="checkbox-wrapper item-wrapper full">
          <input
            type="checkbox"
            id="thirdPartiesCheckbox"
            v-model="signUpForm.thirdPartiesCheckbox"
          />
          <label for="thirdPartiesCheckbox" class="text-justify">{{
            $t("register_form.third_parties_checkbox")
          }}</label>
        </div>
      </div>
    </div>
    <div class="full checkbox-outro text-justify">
      {{ $t("register_form.checkbox_outro") }}
    </div>
    <div
      id="register-button"
      @click="signup"
      class="full"
      :class="{ disabled: !allowRegister() }"
    >
      <p v-if="!performingRequest">
        {{ $t("register_form.submit_button") | uppercase }}
      </p>
      <LoadingDots v-else class="loading" />
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import debounce from "debounce";

import { isEmptyForm } from "../parsers";
const LoadingDots = () => import("./LoadingDots");

export default {
  name: "RegisterForm",
  components: {
    LoadingDots,
  },
  data() {
    return {
      signUpForm: {
        username: "",
        pwd: "",
        pwd2: "",
        comercialCheckbox: false,
        thirdPartiesCheckbox: false,
      },
      modals: {
        username: false,
      },
      showPassword: false,
      showPassword2: false,
      password: null,
      password_length: 0,
      contains_minimum_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      contains_spaces: false,
      valid_password: false,
      initialErrorStyle: true,
      //displayHigher1024: false,
    };
  },
  computed: {
    ...mapState({
      performingRequest: (state) => state.performingRequest,
      usernameError: (state) => state.usernameError,
      passwordError: (state) => state.passwordError,
      userData: (state) => state.userData,
    }),
    /*
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    },
    buttonLabel2() {
      return (this.showPassword2) ? "Hide" : "Show";
    }
    */
  },
  methods: {
    checkUsername: debounce(async function() {
      this.$store.dispatch("requestLoader", true);
      const taken = await this.$store.dispatch(
        "checkUsername",
        this.userData.Email
      );

      console.log(taken, "taken");
      this.$store.dispatch("requestLoader", false);
      this.$store.commit("setUsernameError", taken);
    }, 400),

    allowRegister() {
      const hasEmptyFields = isEmptyForm(this.signUpForm);
      const hasError = this.usernameError || this.passwordError;
      console.log("------>>>>------");
      console.log(hasEmptyFields, "hasEmptyFields");
      console.log(hasError, "hasError");
      console.log(this.userData.UserNumber, "UserNumber");
      console.log(this.userData.Email, "Email");
      console.log(this.userData, "userData");
      console.log("------<<<<------");
      return (
        !hasEmptyFields &&
        !hasError &&
        //!!this.userData.UserNumber &&
        !!this.userData.Email
      );
    },
    clearError(inputs) {
      inputs.split("|").forEach((input) => {
        if (this[input]) {
          const commitName = `set${input
            .charAt(0)
            .toUpperCase()}${input.substring(1)}`;
          this.$store.commit(commitName, false);
        }
      });
    },
    async signup() {
      if (!this.allowRegister()) return;
      if (this.performingRequest) return;
      if (!this.valid_password) return;

      this.$store.dispatch("signUp", this.signUpForm);
    },
    mouseOver(input) {
      this.modals[input] = true;
      this.$emit("on-help-hover", this.modals[input]);
    },
    mouseOut(input) {
      this.modals[input] = false;
      this.$emit("on-help-hover", this.modals[input]);
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
    checkPassword() {
      this.password_length = this.signUpForm.pwd.length;
			// const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/; //only for "contains_special_character"
			
      if (this.password_length > 4) {
        this.contains_minimum_characters = true;
      } else {
        this.contains_minimum_characters = false;
			}
			
      this.contains_uppercase = /[A-Z]/.test(this.signUpForm.pwd);
			this.contains_spaces = /^\S+$/.test(this.signUpForm.pwd);
      //this.contains_number = /\d/.test(this.signUpForm.pwd); // check if contains_number
			//this.contains_special_character = format.test(this.signUpForm.pwd); // check if contains_special_character
      
      if (this.contains_minimum_characters === true &&
        this.contains_uppercase === true &&	
        //this.contains_number === true &&
        //this.contains_special_character === true &&
        this.contains_spaces === true ) {
        this.valid_password = true;			
      } else {
        this.valid_password = false;
      }
    },
    enableErrorStyle (){
      this.initialErrorStyle = false;
    },
    /*
    onResize() {
      console.log("window.innerWidth: " + window.innerWidth);
      if (window.innerWidth > 1024) {
        this.displayHigher1024 = true
      } else {
        this.displayHigher1024 = false
      }
    }
    */
  },
  mounted() {
    this.$nextTick(function() {
      this.checkUsername();
    });
  },
  created(){
    //window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    //window.removeEventListener('resize', this.onResize)
},
};
</script>

<style scoped>
.checkbox-intro {
  color: white;
  font-size: 0.9rem;
  font-weight: 100;
  margin-bottom: 1.5rem;
}

.checkbox-outro {
  color: white;
  font-size: 0.9rem;
  font-weight: 100;
}

.legal-container {
  margin-left: 2rem;
}

.checkbox-wrapper {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 16px;
}

.checkbox-wrapper input {
  min-height: 1rem;
  margin-right: 8px;
}

.checkbox-wrapper label {
  font-size: 0.9rem;
  font-weight: 100;
}

.exclamation {
  font-size: 14px;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  margin-right: 1rem;
  text-align: center;
  border-radius: 50%;
  border: 1px solid white;
  background-color: red;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-column-gap: 16px;
}

.grid-container .full {
  grid-column: 1 / 3;
}

#form-wrapper .full {
  grid-column: 1 / 3;
}

.input-error input {
  border-color: red;
}

.input-error div.error-text {
  font-size: small;
  font-weight: 300;
  margin-top: 4px;
  margin-bottom: 4px;
}

.input-error div.error-text .exclamation {
  float: left;
  margin: 4px;
  margin-right: 16px;
}

input:focus {
  outline: none;
}

.modal {
  width: 17rem;
  height: 15rem;
  z-index: 2;
  position: absolute;
  top: -6.7rem;
  left: 19rem;
  pointer-events: none;
  background-image: url(../assets/icons/popupbg.png);
  background-size: cover;
  padding: 32px;
}

.modal p {
  color: black;
}

.bold {
  font-weight: bold;
}

#form-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 60rem;
}
.item-wrapper {
  position: relative;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: white;
  min-height: 5.5rem;
}

.helper-icon {
  position: absolute;
  right: 5px;
  top: 26px;
}

.helper {
  margin-top: 1rem;
}
#fields {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.form-group {
  width: 49%;
}
.form-text-indicator {
  font-weight: 300;
  margin-bottom: 6px;
  font-size: 1rem;
}
input,
select {
  box-sizing: border-box;
  padding: 2px 10px;
  font-size: 1rem;
  min-height: 3rem;
}
.memberNumber {
  position: relative;
}
#questionIconContainer {
  position: absolute;
  right: 85px;
  width: fit-content;
  top: 33%;
}
#whereIsIt {
  background-image: url(../assets/icons/helpbg.png);
  position: absolute;
  right: 4px;
  top: 30%;
  width: 5rem;
  height: 1.8rem;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
#whereIsIt p {
  font-size: 0.7rem;
}
#helpModal {
  width: 17rem;
  height: 10rem;
  z-index: 2;
  border-radius: 5px;
  position: absolute;
  top: -3rem;
  left: 24rem;
  pointer-events: none;
  background-image: url(../assets/icons/popupbg.png);
  background-size: cover;
  padding: 2% 0 2% 5%;
}
#membercodeIcon {
  position: relative;
  height: 100%;
  width: 5.5rem;
  float: left;
}
.tableHelper {
  width: 100%;
}
.barcodeHelper {
  display: flex;
  justify-content: center;
  width: auto;
}
#helpModal p {
  text-align: center;
  margin-top: 15%;
  color: black;
  font-size: 0.9rem;
}
#barcodeIcon {
  height: 4.5rem;
  margin: 0;
}
#register-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 30rem;
  height: 2.5rem;
  background-color: #e73339;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 2px;
  user-select: none;
  margin: 2rem auto auto auto;
}
#register-button:hover {
  cursor: pointer;
}
#register-button:active {
  transform: translateY(1px);
  filter: saturate(150%);
}
.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.disabled:active {
  transform: none !important;
  filter: none !important;
}
.loading {
  height: 100%;
  font-size: 2rem;
}
.errorMsg {
  color: white;
  font-size: 0.875rem;
  max-width: 100%;
}
.errorMsg p {
  margin-bottom: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fixed {
  pointer-events: none;
}

.eyepassword{
  display: block;
  text-indent: -9999px;
  width: 3rem;
  height: 3rem;

}
.eye{
  display: block;
  text-indent: -9999px;
  width: 2.64rem;
  height: 2.64rem;
  background: url("../assets/icons/eye-solid.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.eye-slash{
  display: block;
  text-indent: -9999px;
  width: 2.64rem;
  height: 2.64rem;
  background: url("../assets/icons/eye-slash-solid.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.boxinput{
  display: flex;
  align-content: initial;
  align-items: flex-start;
  width: auto;
  flex-direction: row;
}
.width-100{
  width: 100%;
}

@media screen and (max-width: 1024px) {
  #form-wrapper {
    width: 100%;
  }

  .legal-container {
    margin-left: 0;
  }

  .grid-container {
    grid-template-columns: 100%;
    grid-column: 1 / 3;
  }

  .legal-container {
    grid-column: 1 / 3;
  }

  .grid-container div {
    grid-column: 1 / 2;
  }

  .grid-container .full {
    grid-column: 1 / 2 !important;
  }

  #register-button {
    grid-column: 1 / 2;
    width: 91vw;
  }

  .modal {
    left: 1rem;
  }

  #helpModal {
    left: 1rem;
  }
}

.is_valid { color: rgba(136, 152, 170, 0.8); }
.is_valid:before { width: 100%; }
.errorboxinfo{
  display: grid;
  font-size: 0.8rem;
}
</style>
